<template>
  <section class="m-order-detail m-scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <HeaderBar title="">
      <router-link to="/en/cart" slot="right" class="solt-right">
        <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
      </router-link>
    </HeaderBar>
    <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};`"></div>

    <!-- 订单状态 -->
    <div class="order-status">
      <p>{{orderDetail.order_status_title}}
      <p v-if="[0,1,2].indexOf(orderDetail.order_status) == -1 && !orderDetail.batch_info">{{orderDetail.delivery_method_title}}</p>
      <p v-if="[0,1].indexOf(orderDetail.order_status) != -1 && orderDetail.batch_info">
        Estimated time of arrival at warehouse in 
        {{(orderDetail.batch_info.cn_day - 0) + (orderDetail.batch_info.d_day - 0) + (orderDetail.batch_info.f_day - 0)}} business days. 
      </p>
    </div>

    <!-- 物流信息 -->
    <div class="logistics">
      <p  v-if="orderDetail.delivery_method != 1">Waybill number</p>
      <div class="number van-hairline--bottom" v-if="orderDetail.delivery_method != 1">
        <p v-if="orderDetail.delivery_method != 1">{{orderDetail.order_logistics.carrier_number || '-'}}</p>
        <p v-else>Self-Pickup</p>
        <span v-if="orderDetail.delivery_method != 1 && orderDetail.order_logistics" @click="copyCarrierNumber" class="copy-button"
        :data-clipboard-text="(orderDetail.order_logistics.carrier_number || 'No logistics order number')">Copy</span>
      </div>
      <div class="number van-hairline--bottom" v-if="orderDetail.delivery_method != 1">
        <p>Logistics：{{orderDetail.order_logistics.carrier_name || '-'}}</p>
      </div>
      <div class="address" :style="{paddingTop: orderDetail.delivery_method == 1? 4.5 + 'vw':0,marginTop:orderDetail.delivery_method == 1?0:3.2+'vw'}">
        <p v-if="orderDetail.delivery_method != 1">Shipping to</p>
        <p v-else>Self-pickup at below address:</p>
        <div v-if="orderDetail.delivery_method != 1">
          <span>{{orderDetail.order_logistics.name}} {{orderDetail.order_logistics.surname}}</span>
          <span>{{orderDetail.order_logistics.tel}}</span>
        </div>
        <p v-else class="van-multi-ellipsis--l2">11121 Horseshoe Way Unit 133, Richmond, BC V7A 5G7</p>
      </div>
    </div>

    <!-- 商品信息 -->
    <div class="store-item m-padding" >
      <div class="van-hairline--bottom goods-title">
        <div class="product_detail">Product details</div>
        <div class="view_poster" @click="viewPoster">View poster</div>
      </div>
      <div class="store-title">
          <img src="@/assets/payment/icon_dianpu@2x.png" alt="Store">
          {{orderDetail.shops_title}}
          <span class="m-mooby" style="width:55px" v-show="orderDetail.is_presale == 1">Pre-Sale</span>
      </div>
      <div class="gift-richmond" v-if="orderDetail.order_gift && orderDetail.order_status != 4 && orderDetail.close_status != 2">
          <img class="dp" src="@/assets/lottery/dp-min.png" alt="" />
          <div class="flex">
            <div class="gift-goods">
                <img :src="orderDetail.order_gift.pic" alt="">
            </div>
            <div class="gift-info">
                <p>{{orderDetail.order_gift.title_en}}</p>
            </div>
            <div class="gift-rule">
                <span>x</span><span>1</span>
            </div>
          </div>
      </div>
      <div class="goods-list">
          <div v-for="(item, ind) in orderDetail.order_goods" :key="ind" right-width="145px" @click="$router.push(`/en/goods/detail/${item.goods_id}`)">
              <div class="goods-item">
                  <img :src="item.goods_sku_pic_url" alt="" class="goods-img">
                  <div class="goods-right">
                      <p class="van-multi-ellipsis--l2">{{item.goods_ename}}</p>
                      <div class="sku">{{item.sku_ename}}</div>
                      <div class="total">
                          <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{item.goods_price}}</span>
                          ×{{item.goods_number}}
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="order-tools">
        <span class="btn" @click.prevent="addCart(orderDetail)" v-if="orderDetail.order_status == 0 && orderDetail.order_goods[0].goods_id != '3751'">Edit</span>
        <span class="btn-acitve" @click.prevent="onPay()" v-if="orderDetail.order_status == 0">Pay Now</span>
        <router-link :to="`/en/aftersale/select/${orderDetail.id}`" class="btn-acitve"
        v-if="orderDetail.order_status == 2 || orderDetail.order_status == 1">After Sales</router-link>
        <span class="btn-active" v-if="orderDetail.order_status == 3" @click="onComment(orderDetail.id)">Evaluate</span>
        <!-- <span class="btn-active" v-if="orderDetail.order_status == 4">Buy Again</span> -->
      </div>
    </div>

    <!-- 订单信息 -->
    <div class="order-info">
      <div class="info-box">
        <p>Order details</p>
        <div class="info-cell ">
          <span>Order date</span>
          <p>{{orderDetail.created_at}}</p>
        </div>
        <div class="info-cell">
          <span>Order number</span>
          <p>{{orderDetail.title}}</p>
        </div>
      </div>

      <div class="info-box">
        <p>Payment info</p>
        <div class="info-cell">
          <span>Payment method</span>
          <p>{{orderDetail.pay_method_title}}</p>
        </div>
        <div class="info-cell ">
          <span>Payment time</span>
          <p>{{orderDetail.pay_time_title}}</p>
        </div>
      </div>

      <div class="info-box">
        <p>Delivery Method <span>{{orderDetail.delivery_method_title}}</span></p>
        <div class="info-cell ">
          <p v-if="orderDetail.delivery_method == 1">11121 Horseshoe Way Unit 133, Richmond, BC V7A 5G7 Canada</p>
          <p v-else>{{orderDetail.order_logistics.address}},{{orderDetail.order_logistics.city_arr ? orderDetail.order_logistics.city_arr.ename : orderDetail.order_logistics.city_name}},{{orderDetail.order_logistics.province_arr.ename}} {{orderDetail.order_logistics.country_arr.ename}}  {{orderDetail.order_logistics.zipcode}}</p>
        </div>
      </div>
      
      <div class="info-box">
        <p>Order details</p>
        <div class="price-cell">
          <span>Price</span>
          <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.goods_amount}}</span>
        </div>
        <div class="price-cell" v-if="orderDetail.pst_amount != '0.00'">
          <span>PST({{((orderDetail.pst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%)</span>
          <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.pst_amount}}</span>
        </div>
        <div class="price-cell" v-if="orderDetail.gst_amount != '0.00'">
          <span>GST({{((orderDetail.gst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%)</span>
          <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.gst_amount}}</span>
        </div>
        <div class="price-cell" v-if="orderDetail.hst_amount != '0.00'">
          <span>HST{{((orderDetail.hst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%</span>
          <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.hst_amount}}</span>
        </div>
        <div class="price-cell" v-if="orderDetail.qst_amount != '0.00'">
          <span>QST{{((orderDetail.qst_amount/(orderDetail.goods_amount - orderDetail.goods_discount_fee))*100).toFixed(0)}}%</span>
          <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.qst_amount}}</span>
        </div>
        <div class="price-cell" v-if="orderDetail.order_logistics.country == 507">
          <span>Taxes</span>
          <span>US$0.00(Tax Package)</span>
        </div>
        <div class="price-cell">
          <span>Postage</span>
          <span>{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.shipping_fee}}</span>
        </div>
        <div class="price-cell" v-if="orderDetail.goods_discount_fee != '0.00'">
          <span>Coupon</span>
          <span class="active">-{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.goods_discount_fee}}</span>
        </div>
        <div class="price-cell" v-if="orderDetail.goods_wallet_fee && orderDetail.goods_wallet_fee != '0.00'">
          <span>Deduction</span>
          <span class="active">-{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.goods_wallet_fee}}</span>
        </div>
        <div class="price-total">
          <span>Order Total:</span>
          <span class="active">{{orderDetail.order_logistics.country == 507 ? 'US$':'C$'}}{{orderDetail.order_total_amount}}</span>
        </div>
      </div>
    </div>
 
    <!-- 为您推荐 -->
    <m-divider title="Recommended" class="recomnd"></m-divider>
    <goods-columns @cartClick="handleCart" :data="remGoodsList"></goods-columns>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>
    
    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
      <CartFlex v-if="start" />
    </transition>
    <div class="friday" v-if="posterShow" @click="posterShow = false">
      <div class="main">
          <div class="poster">
              <img class="title_p" src="@/assets/eleven/title_zh.png" alt="">
              <img class="share-img" :src="imgSrc" alt="">
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import MDivider     from '@/components/en/m-divider.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'
import HeaderBar from '@/components/en/m-header.vue'
import { poster_get } from '@/api/zh/index.js'

import { getOrderRemGoodsList } from '@/api/en/order.js'
import { getOrderDetail,addCart } from '@/api/zh/order.js'
import Clipboard from 'clipboard'
// import { parseTime } from '@/untils/js/common.js'
export default {
  name:'Index',
  components:{ HeaderBar, MDivider, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop:0,
      data:8,
      skuModal:false,
      start:false,
      start_dom:null,
      orderDetail: {order_logistics:{city_arr:{}, country_arr: {}, province_arr:{},carrier_number:''}},
      remGoodsList: [],
      loading: false,
      islock: false,
      page: 1,
      goodsId: '',
      posterShow: false,
      imgSrc: ''
    }
  },
  methods:{
    // 查看海报
    viewPoster() {
      poster_get({pay_title: this.orderDetail.pay_title}).then(res => {  
          if(res){
              this.imgSrc = res.data.pic?.url;
              this.posterShow = true;
          }else{
            this.$notify({ type: 'warning', message: 'No activity poster found for this order!' })
          }
      })
    },
     // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop 
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getOrderRemGoodsListHandle()
        }
        this.islock = true
      }
    },
    // 返回上一页
    handleBack(){
      this.$router.go(-1)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getOrderDetailHandle() {
      // 获取订单详情
      getOrderDetail({id: this.$route.params.id}).then(res => {
        if(res) {
          if(res.data.batch_info) {
            // res.data.batch_info.time.sta_time = parseTime(res.data.batch_info.time.sta_time, '{y}-{m}-{d}')
            // res.data.batch_info.time.end_time = parseTime(res.data.batch_info.time.end_time, '{y}-{m}-{d}')
          }
          this.orderDetail = res.data
        }
      })
    },
    getOrderRemGoodsListHandle() {
      // 获取自定义为你推荐
      this.islock = true
      this.loading  = true
      getOrderRemGoodsList({page: this.page, goods_id: this.$route.params.id}).then(res => {
        this.loading  = false
        if(res) {
          this.remGoodsList = this.remGoodsList.concat(res.data.data)
          this.islock   = false
        }
      })
    },
    copyCarrierNumber() {
      var clipboard = new Clipboard('.copy-button')
      clipboard.on('success', () => {
        this.$notify({ type: 'success', message: 'Copy succeeded' })
      })
      clipboard.on('error', () =>{
        // 不支持复制
        this.$notify({ type: 'warning', message: 'The browser does not support replication'})
      })
    },
    // 加入购物车
    addCart(item){
      let params = {
        order_id:item.id
      }
      addCart(params).then(res =>{
        if(res) {
          this.$router.push('/en/cart')
        }
      })
    },
    onPay() {
      // 继续支付
      localStorage.setItem('order', JSON.stringify(this.orderDetail))
      if(this.orderDetail.order_logistics && this.orderDetail.order_logistics.country == 507) {
        this.$router.replace({path: '/en/usPay', query: {id: this.orderDetail.id}})
      } else {
        this.$router.replace({path: '/en/pay', query: {id: this.orderDetail.id}})
      }
      if(this.orderDetail.order_goods[0].goods_id == '3751') {
        localStorage.setItem('bargain', 1)
      }
    },
    onComment(id) {
      this.$router.push({path: '/en/comment/submit', query: {id: id}})
    }
  },
  created() {
    this.getOrderDetailHandle()
    this.getOrderRemGoodsListHandle()
  }
}
</script>

<style lang="less" scoped>
@import './detail.less';
.gift-richmond {
        width: 100%;
        background-image: url("../../../assets/lottery/Group970.png");
        height: 110px;
        background-size: 100% 100%;
        margin-bottom: 10px;
        position: relative;
        .dp {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.3;
            animation: shan 0.5s infinite;
          }
          @keyframes shan {
            from {
              opacity: 0.3;
            }
            to {
              opacity: 1;
            }
          }
          .flex {
            width: 100%;
            height: 100%;
            padding: 18px 20px 18px 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .gift-goods {
                width: 100px;
                height: 70px;
                background-image: url("../../../assets/lottery/Group972.png");
                background-size: 100% 100%;
                img {
                    display: block;
                    height: 80%;
                    margin: auto;
                }
            }
            .gift-info {
                width: 175px;
                p {
                    &:nth-child(1) {
                        font-size: 15px;
                        line-height: 25px;
                        color: #000;
                    }
                    &:nth-child(2),&:nth-child(3) {
                        font-size: 12px;
                        color: #666666;
                        line-height: 20px;
                    }
                }
            }
            .gift-rule {
                    width: 30px;
                    text-align: center;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    span {
                      display: block;
                        &:nth-child(1) {
                            font-size: 12px;
                        }
                        &:nth-child(1) {
                            font-size: 16px;
                        }
                    }
            }
          }
}
.right-img{width:22px;height:22px;margin:6px}
</style>